.text {
    p {
        margin: 0;
        font-size: 14px;
        a {
            text-decoration: underline;
            color: inherit;
            &:hover {
                color: inherit;
            }
        }
    }
}
